/*Use this as a global stylesheet. For overriding variables, please use base.less or (theme).less*/


//for some reason, the RESULT component's icon size, supposed to be 72px, is overridden by the .anticon class, so we need to specify that the .anticon in .ant-result-icon needs to be 72px
.ant-result-icon > .anticon {
    font-size: 72px !important;
}

.brandImage {
    display: block;
    max-width: 90px;
    max-height: 30px;
    width: auto;
    height: auto;
    border-radius: 10px;
}

.anticon {
    font-size:1.2rem !important;
    vertical-align: baseline !important;   
  }

  .ring-border
  {
    border : 1px solid;
    border-radius: 15px;
    padding: 20px;
  }

  .ant-col-spacing{
    padding-left: 30px;
  }
// //ng zorro select boxes are sometimes too small. This just sets the width to the right size. 

//   .ant-select{
//     display: block !important;
//   }


// .cdk-virtual-scroll-viewport {
//   overflow-x: hidden !important;
// }
// .cdk-virtual-scroll-content-wrapper{
//   width: 100% !important;
// }

.mobile{
    display: inline-flexbox !important;
  
}
.mobile-portrait{
  display: none !important;

}

.mobile-landscape{
  display: none !important;
}

.not-mobile{
  display: none !important;

}

@media (max-width: 599.98px) and (orientation: portrait) {
  .mobile{
    display: none !important;
  }
  .mobile-portrait{
    display: block !important;
  }
  .mobile-landscape{
    display: none !important;
  }
  .not-mobile{
    display: block !important;
  }
}



// @media (max-width: 959.98px) and (orientation: landscape) {
//   .mobile{
//     display: none !important;
//   }
//   .mobile-portrait{
//     display: none !important;
//   }
//   .mobile-landscape{
//     display: inline-flex !important;
//   }
//   .not-mobile{
//     display: block !important;
//   }
// }

.grid-container {
  display: grid;
}

.grid-container--fill {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.grid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.ant-table table {
  border-collapse: collapse;
}

.cdk-virtual-scroll-viewport {
  width: 100% !important;
  contain: content !important;
}

.mobile-view{
  display: block;
  text-align: center;
}
.web-view{
  display: none;
}

@media only screen and (max-width: 768px), (max-device-width: 1024px) {
  .mobile-view{
          display: none !important;
  }  
    
  .web-view{
          display: block;
          text-align: center;
  }
  .inner-content {
    padding-inline: 0px !important;
  }
  
}

.tester{
  display: none;
}

// Mobile view

@media (max-width: 883px){
  .tester{
      display: block;
  }
  body {
    height: 100% !important;
} 

/* Section three*/
.mobile-table{
  .example-item {
    height: 400px !important;
    td{
      height: 50px !important;
    }
  }

  overflow: hidden;
    .ant-table table,  .ant-table thead,  .ant-table tbody,  .ant-table th,  .ant-table td,  .ant-table tr { 
            display: block;
    }
    /* Section four*/
     .ant-table thead tr { 
            position: absolute;
            top: -9999px;
            left: -9999px;
            display: none;
    }
    .ant-table-wrapper {
            clear: both;
            max-width: 92%;
            margin-left: 15px;
            max-height: 100%;
        }
    
/* Section five*/
     td { 
         padding-left: 100px  !important;
         text-align: start !important; 
    }
     .ant-table td:before { 
            position: absolute;
            left: 15px;
            width: 100%; 
            padding-right: 10px; 
            white-space: nowrap;
            font-weight: bold;
            text-align: left;
            // display: none !important;
    }
    .ant-table-measure-now{
      display: none !important;
    }
    /* Section six*/

    .ant-table tbody td:last-child { border-bottom: 4px solid var(--textcolor) !important;}

     .cdk-virtual-scroll-viewport {
            border-radius: 10px;
    }
}
}

@media (max-width: 959.98px) and (orientation: landscape) {
  .tester{
      display: block;
  }
}

// Tablet view

@media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait) {
  .tester{
      display: block;
  }
}

@media (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {
  .tester{
      display: block;
  }
}

// Web view

@media (min-width: 840px) and (orientation: portrait) {
  .tester{
      display: none;
  }
}

@media (min-width: 1280px) and (orientation: landscape) {
  .tester{
      display: none;
  }
}

.ant-rate
{
    color: var(--textcolor) !important;
}

.ant-slider-track {
  background-color: var(--textcolor) !important;
}


// Video Embed Modal Display 
.watch-modal
.ant-modal-content
{
  background-color: #fff0 !important;
  box-shadow: none !important;
}